import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';

import dsd from "../classes/clsDSD";

import { Plus, Trash ,ArrowUp, ArrowDown } from 'react-bootstrap-icons';

import { default as ChildElement } from "../components/FormElement";


class FormElement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            FormData:{},
            isLoaded: false,
            isFailed: false
        };


        this.data = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeBoolean = this.handleChangeBoolean.bind(this);

        this.handleButton = this.handleButton.bind(this);
        this.updateParent = this.updateParent.bind(this);


        this.addChildElement = this.addChildElement.bind(this);
        this.removeChildElement = this.removeChildElement.bind(this);
        this.updateChildElement = this.updateChildElement.bind(this);
        this.moveChildElementUp = this.moveChildElementUp.bind(this);
        this.moveChildElementDown = this.moveChildElementDown.bind(this);

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `.`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};
        

        if (!isEqual(this.state, prevState)){

            if (this.state.Element){
                StateChange.isLoaded = true;
            }

            this.setState(StateChange);

        }

    }

    
    setClass(idClass){

        let StateChange = {};

        let Class = null;

        if (this.state.Classes){
            for (let posC=0;posC<this.state.Classes.length;posC++){
                if (this.state.Classes[posC]._id == idClass){
                    Class = this.state.Classes[posC];
                }
            }
        }


        StateChange.Class = Class;


        this.setState(StateChange);

    }

    setup(){

        let StateChange = {

            isLoaded:false,

            FormData: {},

            Classes:[],

            Element: null,
            seq: null,

            onAdd: null,
            onRemove: null,
            onUpdate: null,
            onMoveUp: null,
            onMoveDown: null,


            optionsOccurs : [],
            optionsRequired : []

        }

        StateChange.onAdd = getParam('onAdd', this.props);
        StateChange.onRemove = getParam('onRemove', this.props);
        StateChange.onUpdate = getParam('onUpdate', this.props);
        StateChange.onMoveUp = getParam('onMoveUp', this.props);
        StateChange.onMoveDown = getParam('onMoveDown', this.props);
        


        StateChange.mode = getParam('mode', this.props);

        StateChange.Classes = getParam('Classes', this.props);

        StateChange.Element = getParam('Element', this.props);
        if (isNull(StateChange.Element)){
            StateChange.Element = {};
        }
        StateChange.FormData = StateChange.Element;

        StateChange.seq = getParam('seq', this.props);

        if (StateChange.mode == null){
            StateChange.mode = 'edit';
        }

        this.setState(StateChange);

        this.setOptions();

    }


    setOptions(){

        let StateChange = {}

        StateChange.optionsRequired = this.dsd.config.Required;
        StateChange.optionsOccurs = this.dsd.config.Occurs;

        this.setState(StateChange);

    }


    handleButton(event) {
    
        let StateChange = {};

        switch (event.currentTarget.name){
            case 'btnAdd':
                StateChange.mode = 'edit';

                if (this.state.onAdd){
                    this.state.onAdd();
                    break;
                }
                break;
            case 'btnDelete':

                if (this.state.onRemove){
                    this.state.onRemove(this.state.seq);
                    break;
                }

                break;

            case 'btnUp':

                if (this.state.onMoveUp){
                    this.state.onMoveUp(this.state.seq);
                    break;
                }

                break;

            case 'btnDown':

                if (this.state.onMoveDown){
                    this.state.onMoveDown(this.state.seq);
                    break;
                }

                break;
                

        }
    
        this.setState(StateChange);
    
        event.preventDefault();    

    };


    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});

        switch (FormFieldName){
            case 'idClass':
                this.setClass(FormFieldValue)
                break;    
        }


    };


    handleChangeBoolean(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        switch (FormFieldValue){
            case 'true':
                FormFieldValue = true;
                break;
            case 'false':
                FormFieldValue = false;
                break;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});

    };


    updateParent(){    

        if (this.state.onUpdate){
            this.state.onUpdate(this.state.seq, this.state.FormData);
            return;
        }
    }


    addChildElement(){
        let FormData = this.state.FormData;

        if (!('Children' in FormData)){
            FormData['Children'] = [];
        }
        if (!FormData.Children){
            FormData['Children'] = [];
        }

        FormData.Children.push({});
        this.setState({"FormData": FormData});

    }

    removeChildElement(seq){

        let FormData = this.state.FormData;

        if (!('Children' in FormData)){
            FormData['Children'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Children.length;pos++){
            let item = FormData.Children[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Children = arrNew;
        this.setState({"FormData": FormData});

        this.updateParent();

    }


    moveChildElementUp(seq){

        let FormData = this.state.FormData;

        if (!('Children' in FormData)){
            FormData['Children'] = [];
        }

        let arrNew = [];
        for (let pos=0;pos<FormData.Children.length;pos++){
            let item = FormData.Children[pos];
            if (pos == (seq-2)){
                item = FormData.Children[pos+1];
            }
            if (pos == (seq-1)){
                item = FormData.Children[pos-1];
            }
            arrNew.push(item); 
        }

        FormData.Children = arrNew;
        this.setState({"FormData": FormData});

    }

    moveChildElementDown(seq){

        let FormData = this.state.FormData;

        if (!('Children' in FormData)){
            FormData['Children'] = [];
        }

        let arrNew = [];
        for (let pos=0;pos<FormData.Children.length;pos++){
            let item = FormData.Children[pos];
            if (pos == (seq-1)){
                item = FormData.Children[pos+1];
            }
            if (pos == (seq)){
                item = FormData.Children[pos-1];
            }
            arrNew.push(item); 
        }

        FormData.Children = arrNew;
        this.setState({"FormData": FormData});

    }



    updateChildElement(seq, Element){


        if ('description' in Element){
            Element.description = sanitizeHtml(Element.description);
        }


        let FormData = this.state.FormData;

        if (!('Children' in FormData)){
            FormData['Children'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Children.length;pos++){
            let item = FormData.Children[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Element
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Element);
        }

        FormData.Children = arrNew;
        this.setState({"FormData": FormData});

        this.updateParent();

    }



    render() {

        if (this.state.isFailed) {
            return <PD.ErrorBox>ERROR - Failed to Load</PD.ErrorBox>
        }


        if (this.state.isLoaded) {

            switch (this.state.mode){
                case 'new':
                case 'edit':
                    return (
                        <form>
                            <PD.Table type="list">
                                <PD.Tr>
                                    <PD.Td>
                                        {(() => {
                                            switch (this.state.mode) {
                                                case "new": 
                                                    return <button name="btnAdd" onClick={this.handleButton}><Plus/></button>;
                                                case "edit": 
                                                    return(
                                                        <div>
                                                            <div>
                                                                <button name="btnDelete" onClick={this.handleButton}><Trash/></button>
                                                            </div>
                                                            {this.state.onMoveUp &&
                                                                <div>
                                                                    <button name="btnUp" onClick={this.handleButton}><ArrowUp/></button>
                                                                </div>
                                                            }
                                                            {this.state.onMoveDown &&
                                                                <div>
                                                                    <button name="btnDown" onClick={this.handleButton}><ArrowDown/></button>
                                                                </div>
                                                            }
                                                        </div>
                                                    );
                                            }
                                        })()}
                                    </PD.Td>
                                    {this.state.mode == 'edit' &&
                                        <React.Fragment>

                                            {this.state.Classes &&
                                                <fieldset>
                                                    <legend>Class</legend>  

                                                    <PD.Select label='Class' name='idClass'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.idClass}>
                                                        {
                                                            this.state.Classes.map(
                                                                (Class, pos) => 
                                                                    {
                                                                        return(
                                                                            <option key={pos} value={Class._id}>{Class.name}</option>
                                                                        )
                                                                    }
                                                            )
                                                        }
                                                    </PD.Select>

                                                </fieldset>
                                            }

                                            <PD.Td>
                                                <PD.Input label="Label" width="20" name="label" value={this.state.FormData.label} onChange={this.handleChange} onBlur={this.updateParent}/>                                        
                                            </PD.Td>

                                            <PD.Td>
                                                {(() => {
                                                    switch (this.state.optionsOccurs.length){
                                                        case 0:
                                                            return null;
                                                        case 1:
                                                            return(
                                                                <>
                                                                    <label>Occurs:</label>
                                                                    {this.state.FormData.occurs}
                                                                </>
                                                            )
                                                        default:
                                                            return(
                                                                <PD.Select label='Occurs' name='occurs'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.occurs}>
                                                                    {
                                                                        this.state.optionsOccurs.map(
                                                                            (occurs, pos) => 
                                                                                {
                                                                                    return(
                                                                                        <option key={pos} value={occurs}>{occurs}</option>
                                                                                    )
                                                                                }
                                                                        )
                                                                    }
                                                                </PD.Select>
                                                            )
                                                    }
                                                })()}

                                            </PD.Td>

                                            <PD.Td>

                                                {(() => {
                                                    switch (this.state.optionsRequired.length){
                                                        case 0:
                                                            return null;
                                                        case 1:
                                                            return(
                                                                <>
                                                                    <label>Required:</label>
                                                                    {this.state.FormData.required}
                                                                </>
                                                            )
                                                        default:
                                                            return(

                                                                <PD.Select label='Required' name='required'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.required}>
                                                                    {
                                                                        this.state.optionsRequired.map(
                                                                            (required, pos) => 
                                                                                {
                                                                                    return(
                                                                                        <option key={pos} value={required}>{required}</option>
                                                                                    )
                                                                                }
                                                                        )
                                                                    }
                                                                </PD.Select>
                                                            )
                                                    }
                                                })()}

                                            </PD.Td>


                                        </React.Fragment>
                                    }
                                </PD.Tr>

                            </PD.Table>

                            {this.state.mode == "edit" &&
                                <div style={{marginLeft:"30px"}}>
                                    {this.FormChildElements()}
                                </div>
                            }   
                        </form>


                    );
                    break;
            };
        };

        return null;
    };


    FormChildElements(){

        let seqNext = 1;
        if (this.state.FormData.Children){
            seqNext = this.state.FormData.Children.length + 1;
        }
        return (
            <fieldset>
                <legend>Children</legend>
                {this.state.FormData.Children &&
                    this.state.FormData.Children.map(
                        (Child, posChild) => 
                        {
                            let seq = posChild+1;
                            return(
                                this.FormChildElement('edit', seq, Child)
                            )
                        }
                    )
                }
                {this.FormChildElement('new', seqNext)}
            </fieldset>
        );

    }

    FormChildElement(mode, seq, Element = {}){

        let onMoveUp = null;
        if (seq>1) {
            onMoveUp = this.moveChildElementUp;
        }

        let onMoveDown = null;
        if (this.state.FormData.Children){
            if (seq<this.state.FormData.Children.length) {
                onMoveDown = this.moveChildElementDown;
            }
        }


        return(
            <div>
                <ChildElement 
                    key={seq.toString()}
                    mode={mode}
                    parent={this}
                    onAdd={this.addChildElement}
                    onRemove={this.removeChildElement}
                    onUpdate={this.updateChildElement}
                    {...(onMoveUp && { onMoveUp:onMoveUp})}
                    {...(onMoveDown && { onMoveDown:onMoveDown})}
                    Classes={this.state.Classes}
                    Element={Element}
                    seq={seq}/>
            </div>
        )
    }


};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <FormElement {...props} navigation={navigation} location={location} params={params} />;
  }
