import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import {cloneObject, getParam} from '../utils/utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

import Buffering from "../components/Buffering"; 



class SelectRelationship extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            renderState: null
        };

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.handleChange = this.handleChange.bind(this);
    

    }

    componentDidMount(){

        this.setup();
 
     }
 
     componentDidUpdate(prevProps, prevState){
 
        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){


            if (this.state.idSelected != prevState.idSelected){
                if (this.state.idSelected){
                    this.state.loadSelectedRelationship = true;
                }
            }

            if (this.state.isLoadedSelectedRelationship){
                if (this.state.SelectedRelationship.LogicalModel){
                    StateChange.idLogicalModel = this.state.SelectedRelationship.LogicalModel._id;
                }

            }

            if (this.state.idLogicalModel != prevState.idLogicalModel){
                if (this.state.idLogicalModel){
                    StateChange.loadRelationships = true;
                }
            }

            if (this.state.loadLogicalModels){
                this.loadLogicalModels();
            }

            if (this.state.loadRelationships){
                this.loadRelationships();
            }

            if (this.state.loadSelectedRelationship){
                this.loadSelectedRelationship(this.state.idSelected);
                if (this.state.onBlur){
                    this.state.onBlur();
                }
            }


            if (this.state.isFailed){
                StateChange.renderState = "failed";
            }

            if (this.state.renderState == "loading"){
                if (this.state.isLoadedLogicalModels){
                    StateChange.renderState = "loaded";
                }

                if (this.state.isLoadedRelationships){
                    StateChange.renderState = "loaded";
                }
            }
             
            this.setState(StateChange);

        }
        

    
    }
 

    setup(){

        let StateChange = {
            renderState: null,

            loadLogicalModels: false,
            loadRelationships: false,
            loadSelectedRelationship: false,

            selectLogicalModel: false,

            label: "Relationship",
            idSelected: "",
            idLogicalModel: null,

            onChange: null,
            onBlur: null

        }

        StateChange.name = getParam('name', this.props);

        if (getParam('label',this.props)){
            StateChange.label = getParam('label',this.props);
        }

        if (getParam('selectLogicalModel',this.props)){
            StateChange.selectLogicalModel = getParam('selectLogicalModel',this.props);
        }


        StateChange.onChange = getParam('onChange', this.props);
        StateChange.onBlur = getParam('onBlur', this.props);

        StateChange.idLogicalModel = getParam('idLogicalModel', this.props);

        StateChange.idSelected = getParam('value', this.props);


        if (StateChange.selectLogicalModel){
            StateChange.loadLogicalModels = true;
        }
        else
        {
            StateChange.loadRelationships = true;
        }

        this.setState(StateChange);

    }

    loadSelectedRelationship(idRelationship){

        let StateChange = {};
        StateChange.loadSelectedRelationship = false;
        StateChange.isLoadedSelectedRelationship = false;
        StateChange.SelectedRelationship = null;

        StateChange.renderState = "loading";
        this.dsd.getRelationship(idRelationship,"SelectedRelationship","isLoadedSelectedRelationship")
        
        this.setState(StateChange);

        return;
        
    };



    loadRelationships(){

        let StateChange = {};
        StateChange.loadRelationships = false;
        StateChange.isLoadedRelationships = false;
        StateChange.Relationships = null;

        StateChange.renderState = "loading";
        this.dsd.listRelationships({idLogicalModel:this.state.idLogicalModel},"Relationships","isLoadedRelationships")
        
        this.setState(StateChange);

        return;
        
    };


    loadLogicalModels(){

        let StateChange = {};
        StateChange.loadLogicalModels = false;
        StateChange.isLoadedLogicalModels = false;
        StateChange.LogicalModels = null;

        StateChange.renderState = "loading";
        this.dsd.listLogicalModels({},"LogicalModels","isLoadedLogicalModels");
        
        this.setState(StateChange);

        return;
        
    };



    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case "idLogicalModel":
                this.setState({"idLogicalModel":FormFieldValue})
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;
                this.setState({"FormData": FormData});
                break;
        };

    };


    render() {

        switch (this.state.renderState){
            case "invalid":
            case "failed":
            case "denied":
                let Message = "An error has occured";
                if (this.state.errorMessage){
                    Message = this.state.errorMessage;
                }
                return <PD.ErrorBox>{Message}</PD.ErrorBox>
                break;
            case "loading":
                return <Buffering/>
                break;
            case "cancelled":
                return <p>Cancelled</p>
                break;
            case "loaded":

                return(
                    <>                    

                        {this.state.selectLogicalModel && this.state.isLoadedLogicalModels &&
                            <PD.Select label='Select a Logical Model' name='idLogicalModel' onChange={this.handleChange} value={this.state.idLogicalModel}>
                                {
                                    this.state.LogicalModels.map(
                                        (LogicalModel, pos) => 
                                            {
                                                return(
                                                    <option key={pos} value={LogicalModel._id}>{LogicalModel.name}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>
                        }


                        {this.state.isLoadedRelationships &&

                            <PD.Select label={this.state.label} name={this.state.name} onChange={this.state.onChange} onBlur={this.state.onBlur} value={this.state.idSelected}>
                                {
                                    this.state.Relationships.map(
                                        (Relationship, pos) => 
                                            {
                                                return(
                                                    <option key={pos} value={Relationship._id}>{Relationship.label}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>
                        }

                    </>
                )
        }

    }
}


export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <SelectRelationship {...props} navigation={navigation} location={location} params={params} />;
  }
