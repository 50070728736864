import React from "react"
import {  NavLink } from "react-router-dom";
import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';

import dsd from "../classes/clsDSD";

import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';


class Home extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            ModelLoaded: false
        }

        this.dsd = new dsd(this);

    }

    componentDidMount(){

        this.dsd.getModel();
//        this.setState({ModelLoaded:true})

     }


     setLayoutContent(){

        if (this.context.hasLayout){
            
            let Content = {Index:{}};

            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));
            

        }

        return;

    }

    render() {

        

        if (this.state.ModelLoaded){

            this.setLayoutContent();

//            this.model = this.xpd.model;
            return (
                <div>
                    <PD.Heading>Digital Service Designer</PD.Heading>
                    <PD.P>Welcome to the DSD</PD.P>
                    <PD.P>
                        This tool can be used to define ....
                    </PD.P>

                    <ul>
                        <li><NavLink exact to={`/conceptmodels`} >Concept Models</NavLink> - Concept Models</li>
                        <li><NavLink exact to={`/logicalmodels`} >Logical Models</NavLink> - Logical Models</li>
                        <li><NavLink exact to={`/datadictionaries`} >Data Dictionaries</NavLink> - Data Dictionaries</li>
                        <li><NavLink exact to={`/dataspecs`} >Data Specifications</NavLink> - Data Specifications</li>
                        <li><NavLink exact to={`/packages`} >Packages</NavLink> - Packages for Use Cases</li>
                        <li><NavLink exact to={`/lists`} >Lists</NavLink> - Lists</li>
                        <li><NavLink exact to={`/patterns`} >Patterns</NavLink> - Patterns</li>
                        <li><NavLink exact to={`/processes`} >Processes</NavLink> - Processes</li>
                        <li><NavLink exact to={`/artefacts`} >Artefacts</NavLink> - Artefacts</li>
                        <li><NavLink exact to={`/resources`} >Resources</NavLink> - Resources</li>
                        <li><NavLink exact to={`/narratives`} >Narratives</NavLink> - Narratives</li>
                    </ul>

                </div>
            )
        }
        if (this.state.isFailed){
            return(
                <PD.ErrorBox>ERROR - Failed</PD.ErrorBox>
            )
        }
        return <div/>

    };
};


export default Home;