import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual } from "lodash";

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';

import dsd from "../classes/clsDSD";

import { Plus, Trash, ArrowUp, ArrowDown } from 'react-bootstrap-icons';

import SelectStructure from "./SelectStructure";

class FormAttribute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            isFailed: false
        };


        this.data = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.updateParent = this.updateParent.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `.`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.Attribute){
                StateChange.FormData = cloneObject(this.state.Attribute);
            }

            if (this.state.Attribute){
                StateChange.isLoaded = true;
            }
    
            this.setState(StateChange);

        }


    }
    
    setup(){

        let StateChange = {

            isLoaded:false,

            FormData: {},

            Attribute: null,
            seq: null,

            onAdd: null,
            onRemove: null,
            onUpdate: null,
            onMoveUp: null,
            onMoveDown: null,

        }

        StateChange.onAdd = getParam('onAdd', this.props);
        StateChange.onRemove = getParam('onRemove', this.props);
        StateChange.onUpdate = getParam('onUpdate', this.props);
        StateChange.onMoveUp = getParam('onMoveUp', this.props);
        StateChange.onMoveDown = getParam('onMoveDown', this.props);


        StateChange.mode = getParam('mode', this.props);

        StateChange.Attribute = getParam('Attribute', this.props);
        if (isNull(StateChange.Attribute)){
            StateChange.Attribute = {};
        }
        StateChange.seq = getParam('seq', this.props);

        if (StateChange.mode == null){
            StateChange.mode = 'edit';
        }

        this.setState(StateChange);
        
    }

    handleButton(event) {
    
        let StateChange = {};

        switch (event.currentTarget.name){
            case 'btnAdd':
                StateChange.mode = 'edit';

                if (this.state.onAdd){
                    this.state.onAdd();
                    break;
                }
                break;
            case 'btnDelete':

                if (this.state.onRemove){
                    this.state.onRemove(this.state.seq);
                    break;
                }

                break;

            case 'btnUp':

                if (this.state.onMoveUp){
                    this.state.onMoveUp(this.state.seq);
                    break;
                }

                break;

            case 'btnDown':

                if (this.state.onMoveDown){
                    this.state.onMoveDown(this.state.seq);
                    break;
                }

                break;

        }

/*        
        switch (event.currentTarget.name){
            case 'btnAdd':
                StateChange.mode = 'edit';
                this.state.parent.addAttribute();
                break;
            case 'btnDelete':
                this.state.parent.removeAttribute(this.state.seq);
                break;
    
            case 'btnUp':

                if (this.state.onMoveUp){
                    this.state.onMoveUp(this.state.seq);
                    break;
                }

                break;

            case 'btnDown':

                if (this.state.onMoveDown){
                    this.state.onMoveDown(this.state.seq);
                    break;
                }

                break;


        }
*/    
        this.setState(StateChange);
    
        event.preventDefault();    

    };


    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});

    };

    updateParent(){    

        if (this.state.onUpdate){
            this.state.onUpdate(this.state.seq, this.state.FormData);
            return;
        }
    }


    render() {

        if (this.state.isFailed) {
            return <PD.ErrorBox>ERROR - Failed to Load</PD.ErrorBox>
        }


        if (this.state.isLoaded) {

            switch (this.state.mode){
                case 'new':
                case 'edit':
                    return (
                        <form>
                            <PD.Table type="list">
                                <PD.Tr>
                                    <PD.Td>
                                        {(() => {
                                            switch (this.state.mode) {
                                                case "new": 
                                                    return <button name="btnAdd" onClick={this.handleButton}><Plus/></button>;
                                                case "edit": 
                                                    return(
                                                        <div>
                                                            <div>
                                                                <button name="btnDelete" onClick={this.handleButton}><Trash/></button>
                                                            </div>
                                                            {this.state.onMoveUp &&
                                                                <div>
                                                                    <button name="btnUp" onClick={this.handleButton}><ArrowUp/></button>
                                                                </div>
                                                            }
                                                            {this.state.onMoveDown &&
                                                                <div>
                                                                    <button name="btnDown" onClick={this.handleButton}><ArrowDown/></button>
                                                                </div>
                                                            }
                                                        </div>
                                                    );                                                
                                            }
                                        })()}
                                    </PD.Td>
                                    {this.state.mode == 'edit' &&
                                        <React.Fragment>
                                            <PD.Td>
                                                <PD.Input label="Name" width="20" name="name" value={this.state.FormData.name} onChange={this.handleChange} onBlur={this.updateParent}/>                                        
                                            </PD.Td>

                                            <PD.Td>
                                                <PD.Radio label="type" name="type" value={this.state.FormData.type} onChange={this.handleChange} onBlur={this.updateParent}>
                                                    <label>data type</label>
                                                    <label>structure</label>
                                                </PD.Radio>                                        
                                            </PD.Td>



                                            <PD.Td>
                                                {(() => {
                                                    switch (this.state.FormData.type) {
                                                        case "structure":
                                                            return (
                                                                <SelectStructure label='Structure' name="idStructure" onChange={this.handleChange} onBlur={this.updateParent}  selectDataDictionary={true} value={this.state.FormData.idStructure}/>
                                                            );
                                                            break;
                                                        case "data type":
                                                        default:
                                                            return(
                                                                <PD.Select label='Data Type' name='dataType'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.dataType}>
                                                                    {
                                                                        Object.keys(this.dsd.config.DataTypes).map(
                                                                            (datatype, pos) => 
                                                                                {
                                                                                    return(
                                                                                        <option key={pos} value={datatype}>{datatype}</option>
                                                                                    )
                                                                                }
                                                                        )
                                                                    }
                                                                </PD.Select>
                                                            )
                                                    }
                                                })()}
                                            </PD.Td>

                                            <PD.Td>
                                                <PD.Select label='Occurs' name='occurs'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.occurs}>
                                                    {
                                                        this.dsd.config.Occurs.map(
                                                            (occurs, pos) => 
                                                                {
                                                                    return(
                                                                        <option key={pos} value={occurs}>{occurs}</option>
                                                                    )
                                                                }
                                                        )
                                                    }
                                                </PD.Select>
                                            </PD.Td>

                                            <PD.Td>
                                                <PD.Select label='Required' name='required'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.required}>
                                                    {
                                                        this.dsd.config.Required.map(
                                                            (required, pos) => 
                                                                {
                                                                    return(
                                                                        <option key={pos} value={required}>{required}</option>
                                                                    )
                                                                }
                                                        )
                                                    }
                                                </PD.Select>
                                            </PD.Td>



                                        </React.Fragment>
                                    }
                                </PD.Tr>

                                {this.state.mode == 'edit' &&
                                    <PD.Tr>
                                        <PD.Td/>
                                        <PD.Td colSpan={4}>
                                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}  onBlur={this.updateParent}/>
                                        </PD.Td>
                                    </PD.Tr>
                                }
                            </PD.Table>
                        </form>
                    );
                    break;
            };
        };

        return null;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <FormAttribute {...props} navigation={navigation} location={location} params={params} />;
  }
