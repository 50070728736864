import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import {cloneObject, getParam} from '../utils/utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

import Buffering from "../components/Buffering"; 



class SelectEntity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            renderState: null
        };

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.handleChange = this.handleChange.bind(this);
    

    }

    componentDidMount(){

        this.setup();
 
     }
 
     componentDidUpdate(prevProps, prevState){
 
        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){


            if (this.state.idSelected != prevState.idSelected){
                if (this.state.idSelected){
                    this.state.loadSelectedEntity = true;
                }
            }

            if (this.state.isLoadedSelectedEntity){
                if (this.state.SelectedEntity.LogicalModel){
                    StateChange.idLogicalModel = this.state.SelectedEntity.LogicalModel._id;
                }

            }

            if (this.state.idLogicalModel != prevState.idLogicalModel){
                if (this.state.idLogicalModel){
                    StateChange.loadEntities = true;
                }
            }

            if (this.state.loadLogicalModels){
                this.loadLogicalModels();
            }

            if (this.state.loadEntities){
                this.loadEntities();
            }

            if (this.state.loadSelectedEntity){
                this.loadSelectedEntity(this.state.idSelected);
                if (this.state.onBlur){
                    this.state.onBlur();
                }
            }


            if (this.state.isFailed){
                StateChange.renderState = "failed";
            }

            if (this.state.renderState == "loading"){
                if (this.state.isLoadedLogicalModels){
                    StateChange.renderState = "loaded";
                }

                if (this.state.isLoadedEntities){
                    StateChange.renderState = "loaded";
                }
            }
             
            this.setState(StateChange);

        }
        

    
    }
 

    setup(){

        let StateChange = {
            renderState: null,

            loadLogicalModels: false,
            loadEntities: false,
            loadSelectedEntity: false,

            selectLogicalModel: false,

            label: "Entity",
            idSelected: "",
            idLogicalModel: null,

            onChange: null,
            onBlur: null

        }

        StateChange.name = getParam('name', this.props);

        if (getParam('label',this.props)){
            StateChange.label = getParam('label',this.props);
        }

        if (getParam('selectLogicalModel',this.props)){
            StateChange.selectLogicalModel = getParam('selectLogicalModel',this.props);
        }


        StateChange.onChange = getParam('onChange', this.props);
        StateChange.onBlur = getParam('onBlur', this.props);

        StateChange.idLogicalModel = getParam('idLogicalModel', this.props);

        StateChange.idSelected = getParam('value', this.props);


        if (StateChange.selectLogicalModel){
            StateChange.loadLogicalModels = true;
        }
        else
        {
            StateChange.loadEntities = true;
        }

        this.setState(StateChange);

    }

    loadSelectedEntity(idEntity){

        let StateChange = {};
        StateChange.loadSelectedEntity = false;
        StateChange.isLoadedSelectedEntity = false;
        StateChange.SelectedEntity = null;

        StateChange.renderState = "loading";
        this.dsd.getEntity(idEntity,"SelectedEntity","isLoadedSelectedEntity")
        
        this.setState(StateChange);

        return;
        
    };



    loadEntities(){

        let StateChange = {};
        StateChange.loadEntities = false;
        StateChange.isLoadedEntities = false;
        StateChange.Entities = null;

        StateChange.renderState = "loading";
        this.dsd.listEntities({idLogicalModel:this.state.idLogicalModel},"Entities","isLoadedEntities")
        
        this.setState(StateChange);

        return;
        
    };


    loadLogicalModels(){

        let StateChange = {};
        StateChange.loadLogicalModels = false;
        StateChange.isLoadedLogicalModels = false;
        StateChange.LogicalModels = null;

        StateChange.renderState = "loading";
        this.dsd.listLogicalModels({},"LogicalModels","isLoadedLogicalModels");
        
        this.setState(StateChange);

        return;
        
    };



    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case "idLogicalModel":
                this.setState({"idLogicalModel":FormFieldValue})
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;
                this.setState({"FormData": FormData});
                break;
        };

    };


    render() {

        switch (this.state.renderState){
            case "invalid":
            case "failed":
            case "denied":
                let Message = "An error has occured";
                if (this.state.errorMessage){
                    Message = this.state.errorMessage;
                }
                return <PD.ErrorBox>{Message}</PD.ErrorBox>
                break;
            case "loading":
                return <Buffering/>
                break;
            case "cancelled":
                return <p>Cancelled</p>
                break;
            case "loaded":

                return(
                    <>                    

                        {this.state.selectLogicalModel && this.state.isLoadedLogicalModels &&
                            <PD.Select label='Select a Logical Model' name='idLogicalModel' onChange={this.handleChange} value={this.state.idLogicalModel}>
                                {
                                    this.state.LogicalModels.map(
                                        (LogicalModel, pos) => 
                                            {
                                                return(
                                                    <option key={pos} value={LogicalModel._id}>{LogicalModel.name}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>
                        }


                        {this.state.isLoadedEntities &&

                            <PD.Select label={this.state.label} name={this.state.name} onChange={this.state.onChange} onBlur={this.state.onBlur} value={this.state.idSelected}>
                                {
                                    this.state.Entities.map(
                                        (Entity, pos) => 
                                            {
                                                return(
                                                    <option key={pos} value={Entity._id}>{Entity.name}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>
                        }

                    </>
                )
        }

    }
}

export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <SelectEntity {...props} navigation={navigation} location={location} params={params} />;
  }
