import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual } from "lodash";

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';

import SelectLink from './SelectLink';


import dsd from "../classes/clsDSD";

import { Plus, Trash } from 'react-bootstrap-icons';

class FormLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            isFailed: false
        };


        this.data = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.updateParent = this.updateParent.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `.`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.Link){
                StateChange.FormData = cloneObject(this.state.Link);
            }

            if (this.state.Link){
                StateChange.isLoaded = true;
            }
    
            this.setState(StateChange);

        }


    }
    
    setup(){

        let StateChange = {

            isLoaded:false,

            FormData: {},

            Link: null,
            seq: null,

            onAdd: null,
            onRemove: null,
            onUpdate: null,

//            parent:null,

        }


        StateChange.onAdd = getParam('onAdd', this.props);
        StateChange.onRemove = getParam('onRemove', this.props);
        StateChange.onUpdate = getParam('onUpdate', this.props);
        
//        StateChange.parent = getParam('parent', this.props);
        StateChange.mode = getParam('mode', this.props);

        StateChange.Link = getParam('Link', this.props);
        if (isNull(StateChange.Link)){
            StateChange.Link = {};
        }
        StateChange.seq = getParam('seq', this.props);

        if (StateChange.mode == null){
            StateChange.mode = 'edit';
        }

        this.setState(StateChange);
        
    }

    handleButton(event) {
    
        let StateChange = {};

        switch (event.currentTarget.name){

            case 'btnAdd':
                StateChange.mode = 'edit';

                if (this.state.onAdd){
                    this.state.onAdd();
                    break;
                }
                break;
            case 'btnDelete':

                if (this.state.onRemove){
                    this.state.onRemove(this.state.seq);
                    break;
                }

                break;
    
        }
    
        this.setState(StateChange);
    
        event.preventDefault();    

    };


    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});

    };

    updateParent(){    

        if (this.state.onUpdate){
            this.state.onUpdate(this.state.seq, this.state.FormData);
            return;
        }
    }


    render() {

        if (this.state.isFailed) {
            return <PD.ErrorBox>ERROR - Failed to Load</PD.ErrorBox>
        }


        if (this.state.isLoaded) {

            switch (this.state.mode){
                case 'new':
                case 'edit':
                    return (
                        <form>
                            <PD.Table type="list">                        
                                <PD.Tr>
                                    <PD.Td>
                                        {(() => {
                                            switch (this.state.mode) {
                                                case "new": 
                                                    return <button name="btnAdd" onClick={this.handleButton}><Plus/></button>;
                                                case "edit": 
                                                    return <button name="btnDelete" onClick={this.handleButton}><Trash/></button>;
                                            }
                                        })()}
                                    </PD.Td>
                                    {this.state.mode == 'edit' &&
                                        <React.Fragment>
                                            <PD.Td>
                                                <SelectLink label='Link' name="idLink" onChange={this.handleChange} onBlur={this.updateParent} selectConceptModel={true} value={this.state.FormData.idLink}/>                                        
                                            </PD.Td>
                                            <PD.Td>
                                                <PD.Checkbox label="Inverse?" name="inverse" value={this.state.FormData.inverse} onChange={this.handleChange} onBlur={this.updateParent}/>
                                            </PD.Td>
                                        </React.Fragment>
                                    }
                                </PD.Tr>
                            </PD.Table>
                        </form>
                    );
                    break;
            };
        };

        return null;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <FormLink {...props} navigation={navigation} location={location} params={params} />;
  }
