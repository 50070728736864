import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import {cloneObject, getParam, formatText, formatDate, truncate} from '../utils/utils';
import {SanitizeHTML,  HtmlToText} from '../utils/utils';

import dsd from "../classes/clsDSD";

import * as PD from '../classes/pdStyle';

import { CheckSquare } from 'react-bootstrap-icons';


class TreeSerialisation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            Versions : null,
            Controls: {}
        };

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.handleExpand = this.handleExpand.bind(this);

    }

componentDidMount(){

    this.setup();
 
}



componentDidUpdate(prevProps, prevState){

   if (!isEqual(this.props, prevProps)){
       this.setup();
   }

   if (!isEqual(this.state, prevState)){
        if (this.state.load){
            this.loadData();
        }

   }

}

loadData(){

    this.dsd.getDataSpec(this.state.idDataSpec);
    this.setState({load:false});
}

setup(){

    let StateChange = {
        load: false,
        idDataSpec: null,
        Controls: {}
    }

    if (getParam('idDataSpec', this.props)){
        StateChange.idDataSpec = getParam('idDataSpec', this.props);
    }

    StateChange.load = true;

    this.setState(StateChange);
        
}


handleExpand(event) {

    const ControlName = event.target.name;
    let ControlValue = false;

    if (event.target.value == 'true'){
        ControlValue = true;
    }

    let Controls = this.state.Controls;
    Controls[ControlName] = ControlValue;
    this.setState({"Controls": Controls});

    return;

};

    render() {


        if (this.state.isFailed) {
            return (
                <PD.ErrorBox>
                    A problem has occurred
                </PD.ErrorBox>
            )
        }


        if (this.state.isLoaded) {

            let Element = null;

            if (this.state.DataSpec.Serialisation){
                if (this.state.DataSpec.Serialisation.Root){
                    Element = this.state.DataSpec.Serialisation.Root
                }
            }

            return (
                <div>
                    {this.renderElements([Element])}
                </div>
            )


        }
    }

    renderElements(arrElements){

        if (arrElements.length){

            return (
                    <div>
                        {
                            arrElements.map(
                                (Element, pos) => 
                                {
                                    return(
                                        this.renderElement(Element)
                                    )
                                }
                            )
                        }
                    </div>

            )
            return null;
        }
        return null;


    }

    renderElement(Element){

        let ElementType = null;
        let ElementLabel = 'none';

        let ChildElements = null;
        let ChildProperties = null;

        if (Element){

            ElementType = 'field';
            if (Element.Children){
                if (Element.Children.length){
                    ElementType = 'object';
                }
            }
            if (Element.occurs == 'many'){
                ElementType = 'array';
            }
            if (Element.name){
                ElementLabel = Element.name;
            }
            if (Element.label){
                ElementLabel = Element.label;
            }

            let ElementInfo = '';

            if ( Element.dataType){
                ElementInfo = `${ElementInfo} ${Element.dataType}`;
            }
            if ( Element.occurs == 'many'){
                ElementInfo = `${ElementInfo} array`;
            }
            if ( Element.required == 'yes'){
                ElementInfo = `${ElementInfo} required`;
            }



            ElementInfo = ElementInfo.trim();
            if (ElementInfo){
                ElementInfo = `(${ElementInfo})`
            }

            if (Element.Children){
                if (Element.Children.length){
                    ChildElements = Element.Children;
                }
            }
            if (Element.Class){
                if (Element.Class.Properties){
                    if (Element.Class.Properties.length){
                        ChildProperties = Element.Class.Properties;
                    }
                }
            }
            if (Element.Properties){
                if (Element.Properties.length){
                    ChildProperties = Element.Properties;
                }
            }
            let Expandable = false;
            if (ChildProperties){
                Expandable = true;                
            }
            if (ChildElements){
                Expandable = true;                
            }

            let Expanded = false;
            let ExpandAction = '+'
            let ExpandValue = true;
            if (`btnExpand_${Element._id}` in this.state.Controls){
                if (this.state.Controls[`btnExpand_${Element._id}`]){
                    Expanded = true;
                    ExpandAction = '-';
                    ExpandValue = false;
                }
            }

            return(

                <div>
                    <span style={{display:'inline-block',width:'20px'}}>
                        {Expandable &&
                            <button  style={{padding:0}} name={`btnExpand_${Element._id}`} value={ExpandValue} onClick={this.handleExpand}>{ExpandAction}</button>
                        }
                    </span>
                    <span>
                    {ElementLabel} {ElementInfo}

                    {Expanded &&
                        <div style={{marginLeft:"30px"}}>
                            {ChildProperties &&
                                this.renderElements(ChildProperties)}
                            {ChildElements &&
                                this.renderElements(ChildElements)}
                        </div>
                    }
                    </span>
                </div>
            )
        }
        return null;


    }



};
        
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <TreeSerialisation {...props} navigation={navigation} location={location} params={params} />;
  }
