import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';

import Buffering from "../components/Buffering"; 

import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewStep from "../components/ViewStep";
import ViewProcess from "../components/ViewProcess";
import ViewMetadata from "../components/ViewMetadata";

import StepOptionRow from "../components/StepOptionRow";
import StepFlowRow from "../components/StepFlowRow";
import StepArtefactRow from "../components/StepArtefactRow"
import StepResourceRow from "../components/StepResourceRow"



import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

class Step extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            renderState: null
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/`;

    }

    loadProcesses(){
        this.dsd.listProcesses({},"optProcesses");
        return;
    }


    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.vizNodeId){
                let partsVizNodeId = this.state.vizNodeId.split('_');
                switch(partsVizNodeId[0]){
                    case 'step':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/step/${partsVizNodeId[1]}`);
                        }
                        break;
                }
            }

            if (this.state.loadProcess){
                this.loadProcess();
            }

            if (this.state.loadStep){
                this.loadStep();
            }

            if (this.state.renderState == "loading"){
                if (this.state.isFailed){
                    StateChange.renderState = "failed";
                }
                else{
                    switch (this.state.mode){
                        case "new":
                            if (this.state.isLoadedProcess){
                                StateChange.renderState = "loaded";
                            }
                        default:
                            if (this.state.isLoadedStep){
                                StateChange.Process = this.state.Step.Process;
                                StateChange.renderState = "loaded";
                            }
                            break;                        
                    }
                }
            }

            if (this.state.Process != prevState.Process){
                if (this.state.Process){
                    this.FallBackNavigation = `/process/${this.state.Process._id}`;
                    this.loadProcessSteps();
                }
            }


            if (this.state.renderState == "updating"){
                if (this.state.isError){
                    StateChange.renderState = "error";
                    StateChange.isError = false;
                }
                else{
                    if (this.state.isUpdated){
                        StateChange.isUpdated = false;
                        StateChange.renderState = "updated";
                    }
                }
            }

            if (this.state.renderState == "updated"){
                switch (this.state.mode){
                    case 'remove':
                        this.props.navigation(this.FallBackNavigation);
                        break;
                    default:
                        this.props.navigation(`/step/${this.state.idStep}`);
                        break;
                }
            }

            if (this.state.renderState == "cancelled"){
                switch (this.state.mode){
                    case "edit":
                    case "remove":
                        this.props.navigation(`/step/${this.state.idStep}`);
                        break;
                    case "new":
                        this.props.navigation(this.FallBackNavigation);
                        break;
                }
            }

            if (this.state.renderState == "loaded"){

                StateChange.OfferEdit = false;
                if (this.state.Process){
                    if (this.dsd.loggedon){
                        if (this.dsd.user.id == this.state.Process.idUser){
                            StateChange.OfferEdit = true;
                        }
                    }
                }

                if (!StateChange.OfferEdit){
                    switch (this.state.mode){
                        case "new":
                        case "edit":
                        case "remove":
                            if (!this.state.OfferEdit){
                                StateChange.renderState = "denied"
                                StateChange.errorMessage = "You cannot add or edit Steps to this Process"
                            }
                            break;
                    }
                }

            }
            this.setState(StateChange);

        }


    }
    
    loadProcess(){

        let StateChange = {};
        StateChange.loadProcess = false;
        StateChange.isLoadedProcess = false;
        StateChange.Process = null;

        if (this.state.idProcess){
            StateChange.renderState = "loading";
            this.dsd.getProcess(this.state.idProcess,"Process","isLoadedProcess");
        }

        this.setState(StateChange);

        return;
        
    };

    loadStep(){

        let StateChange = {};
        StateChange.loadStep = false;
        StateChange.isLoadedStep = false;
        StateChange.Step = null;


        if (this.state.idStep){
            StateChange.renderState = "loading";
            this.dsd.getStep(this.state.idStep,"Step","isLoadedStep");
        }

        this.setState(StateChange);

        return;
        
    };


    loadProcessSteps(){

        let StateChange = {};
        StateChange.loadProcessSteps = false;
        StateChange.isLoadedProcessSteps = false;
        StateChange.ProcessSteps = null;


        if (this.state.Process){
            StateChange.renderState = "loading";
            this.dsd.listSteps({idProcess:this.state.Process._id},"ProcessSteps","isLoadedProcessSteps");
        }

        this.setState(StateChange);

        return;
        
    };



    setup(){

        let StateChange = {

            mode: "view",

            idStep: null,
            idProcess: null,

            Process: null,
            loadProcess: false,
            isLoadedProcess: false,

            Step: null,
            loadStep: false,
            isLoadedStep: false,


            selectSteps: null,

            isUpdated: false,

            isFailed: false,
            isError: false,
            errorMessage: null,

            renderState: null,


            FormData: {},
            OfferEdit:false,

            Controls: {
                vizFullSize :true
            },

            vizNodeId: null

        }

        if (getParam('mode', this.props)){
            StateChange.mode = getParam('mode', this.props);
        }
        StateChange.idStep = getParam('id', this.props);
        StateChange.idProcess = getParam('idProcess', this.props);

        this.loadProcesses();

        switch (StateChange.mode){
            case 'new':
                if (!StateChange.idProcess){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Process not specified when adding a Step"
                }
                break;
            default:
                if (StateChange.idProcess){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Process should not be specified for an existing Step"
                }
                if (!StateChange.idStep){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Step not specified"
                }
                break;
        }


        if (StateChange.idProcess){
            StateChange.loadProcess = true;
        }

        if (StateChange.idStep){
            StateChange.loadStep = true;
        }


        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case 'vizFullSize':
                let Controls = this.state.Controls;
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    
    handleSubmit(event) {

        switch (event.target.name){
            
            case 'btnCancel':

                this.setState({
                    renderState: "cancelled"
                })

                break;
            case 'btnSave':

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    renderState: "updating"
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addStepToProcess(this.state.idProcess,this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editStep(this.state.idStep,this.state.FormData);
                        break;
                    default:
                        break; 
                };
                break;

            case 'btnRemove':

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    renderState: "updating"
                })
        
                this.dsd.removeStep(this.state.Process._id, this.state.idStep);

                break;

        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
        }

        this.setState(StateChange);

        event.preventDefault();
    };


    addFlow(){
        let FormData = this.state.FormData;

        if (!('Flows' in FormData)){
            FormData['Flows'] = [];
        }
        if (!FormData.Flows){
            FormData['Flows'] = [];
        }

        FormData.Flows.push({});
        this.setState({"FormData": FormData});
    }

    removeFlow(seq){

        let FormData = this.state.FormData;

        if (!('Flows' in FormData)){
            FormData['Flows'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Flows.length;pos++){
            let item = FormData.Flows[pos];
            if ((pos) == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Flows = arrNew;
        this.setState({"FormData": FormData});

    }

    updateFlow(seq, Flow){

        let FormData = this.state.FormData;

        if (!('Flows' in FormData)){
            FormData['Flows'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Flows.length;pos++){
            let item = FormData.Flows[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Flow;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Flow);
        }

        FormData.Flows = arrNew;
        this.setState({"FormData": FormData});

    }

    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };


    visualise(){
    
        if (!this.state.Step){
            return null;
        }


        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tr>
                            <td>
                            <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                            </td>
                        </tr>
                    </table>

                    <div name="divViz" style={styleDiv} onClick={this.handleVizClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    render() {

        switch (this.state.renderState){
            case "invalid":
            case "failed":
            case "denied":
                let Message = "An error has occured";
                if (this.state.errorMessage){
                    Message = this.state.errorMessage;
                }
                return <PD.ErrorBox>{Message}</PD.ErrorBox>
                break;
            case "loading":
                return <Buffering/>
                break;
            case "cancelled":
                return <p>Cancelled</p>
                break;
    
        }


        switch (this.state.mode){
            case 'new':
            case 'edit':

                if (this.state.Step){
                    this.state.FormData = this.state.Step;
                }

                switch (this.state.renderState){
                    case "loaded":
                    case "updating":
                    case "error":
                        return (
                            <div>

                                <PD.Heading>{this.state.mode} Step</PD.Heading>

                                {this.state.renderState == "error" &&
                                    <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                                }

                                <form> 
                                    <PD.Input label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                                    <PD.Select label="Type" name='type'  onChange={this.handleChange} value={this.state.FormData.type}>
                                        {
                                            Object.keys(this.dsd.model.steps).map(
                                                (modelKey, posModelStep) => 
                                                    {
                                                        let modelStep = this.dsd.model.steps[modelKey];
                                                        return(
                                                            <option key={posModelStep} value={modelStep.name}>{modelStep.label}</option>
                                                        )
                                                    }
                                            )
                                        }
                                    </PD.Select>

                                    <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>
                                    <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                                    <PD.Input label="Organisation" width="30" name="organisation" value={this.state.FormData.organisation} onChange={this.handleChange}/>
                                    <PD.Input label="Role" width="30" name="role" value={this.state.FormData.role} onChange={this.handleChange}/>

                                    
                                    {this.FormStepSubProcess()}
                                    {this.FormStepMultiInstance()}
                                    {this.FormStepOptions()}
                                    {this.FormStepFlows()}
                                    {this.FormStepArtefacts()}
                                    {this.FormStepResources()}


                                    {(() => {
                                        switch (this.state.renderState) {
                                            case "updating":
                                                return (
                                                    <div>
                                                        <PD.Button disabled={true} type="submit" name="btnSave">
                                                            <Buffering/>
                                                        </PD.Button>
                                                    </div>
                                                )
                                            default:
                                                return (
                                                        <div>
                                                            <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave" value="Save" />    
                                                            <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" /> 
                                                        </div>
                                                )
                                        }
                                    })()}

                                </form>

                            </div>    
                        );
                }
                break;
            case 'remove':
                return (
                    <div>
                        <PD.Heading>{this.state.mode} Step</PD.Heading>

                        {this.state.isFailed &&
                            <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                        }

                        <PD.Button onClick={this.handleSubmit} type="warning" name="btnRemove" value="confirm remove?" />
                        <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" /> 
                        <ViewStep Step={this.state.Step} ProcessSteps={this.state.ProcessSteps}/>

                    </div>
                )
            case 'view':
            default:
                
                switch (this.state.renderState){
                    case "loaded":

//                        this.viz = this.dsd.vizConcept({Concept:this.state.Concept, ConceptLinks:this.state.ConceptLinks}, this.state.Controls, this.vizEngine );

                        return (
                            <div>
                                <div>
                                    <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                    <PD.Heading>Step:{this.state.Step.name}</PD.Heading>
                                </div>

                                {this.state.OfferEdit &&
                                    <span style={{paddingRight:10}}>
                                            <PD.Button onClick={this.handleMode} type="submit" value="edit" />   
                                            <PD.Button type="warning" onClick={this.handleMode} value="remove" /> 
                                    </span>
                                }

                                <ViewStep Step={this.state.Step} ProcessSteps={this.state.ProcessSteps}/>

                                <br/>

                                <PD.Tabs>

{/*
                                    <div label='Visualise'>
                                        <table>
                                            <tr>
                                                <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                    {this.visualise()}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
*/}

                                    <div label='Process'>
                                        <ViewProcess Process={this.state.Process} />
                                    </div>

                                    <div label='Metadata'>
                                        <ViewMetadata Data={this.state.Step} />
                                    </div>


                                    <div label="used in ...">
                                    </div>

                                    <div label='Developer'>
                                        <table>
                                            {this.viz &&
                                                <tr>
                                                    <th style={{verticalAlign:"top"}}>dot</th>
                                                    <td><pre>{this.viz.dot}</pre></td>
                                                </tr>
                                            }
                                            {this.state.csv &&
                                                <tr>
                                                        <th style={{verticalAlign:"top"}}>csv</th>
                                                        <td><pre>{this.state.csv}</pre></td>
                                                </tr>
                                            }
                                        </table>

                                    </div>


                                </PD.Tabs>


                            </div>
                        )
                }
        };

        return <div />;
    };



    FormStepMultiInstance(){

        if (!this.state.FormData.type){
            return null
        }

        let modelStep = null;
        if (this.state.FormData.type in this.dsd.model.steps){
            modelStep = this.dsd.model.steps[this.state.FormData.type];
        }
        if (!modelStep){
            return null
        }

        switch (modelStep.type){
            case 'bpmn:task':
            case 'bpmn:subprocess':
                return(
                    <div>
                        <PD.Checkbox label="Multi Instance?" name="multiinstance" value={this.state.FormData.multiinstance} onChange={this.handleChange}/>
                        {this.state.FormData.multiinstance &&
                            <PD.Input label="For each" width="30" name="foreach" value={this.state.FormData.foreach} onChange={this.handleChange}/>
                        }
                    </div>
                    )
                break;
        }

        return null;

    }


    FormStepSubProcess(){

        if (!this.state.FormData.type){
            return null
        }

        let modelStep = null;
        if (this.state.FormData.type in this.dsd.model.steps){
            modelStep = this.dsd.model.steps[this.state.FormData.type];
        }
        if (!modelStep){
            return null
        }

        switch (modelStep.type){
            case 'bpmn:subprocess':
                if (this.state.optProcesses){
                    return(
                        <PD.Select label="Sub Process" name='idSubProcess'  onChange={this.handleChange} value={this.state.FormData.idSubProcess}>
                            {
                                this.state.optProcesses.map(
                                    (optProcess, posOptProcess) => 
                                        {
                                            return(
                                                <option key={posOptProcess} value={optProcess._id}>{optProcess.name}</option>
                                            )
                                        }
                                )
                            }
                        </PD.Select>
                    )
                }
                break;
            }

        return null;

    }

    FormStepOptions(){

        if (!this.state.FormData.type){
            return null
        }

        let modelStep = null;
        if (this.state.FormData.type in this.dsd.model.steps){
            modelStep = this.dsd.model.steps[this.state.FormData.type];
        }
        if (!modelStep){
            return null
        }

        let seqNext = 1;

        switch (modelStep.type){
            case 'bpmn:exclusiveor':
            case 'bpmn:inclusiveor':
                if (this.state.FormData.Options){
                    seqNext = this.state.FormData.Options.length + 1;
                }
                return (
                    <div>
                        <PD.Heading level="2">Decision Options</PD.Heading>
                        <PD.Table type="list">
                            <PD.Tr>
                                <PD.Th/>
                                <PD.Th>Option</PD.Th>
                            </PD.Tr>

                            {this.state.FormData.Options &&
                                this.state.FormData.Options.map(
                                    (stepOption, posStepOption) => 
                                    {
                                        let seq = posStepOption+1;
                                        return(
                                            this.FormStepOption('edit', seq, stepOption)
                                        )
                                    }
                                )
                            }
                            {this.FormStepOption('new', seqNext)}
                        </PD.Table>
                    </div>
                );
                break;
        }
        return null;
    }

    FormStepOption(mode, seq,Option = {}){
        let value = "";
        if (!(Option.label === undefined)){
            value = Option.label;
        }
        return(
            <StepOptionRow key={seq.toString()} mode={mode} parent={this} Option={Option} seq={seq}/>
        )
    }

    addOption(){

        let FormData = this.state.FormData;

        if (!('Options' in FormData)){
            FormData['Options'] = [];
        }
        if (!FormData.Options){
            FormData['Options'] = [];
        }

        FormData.Options.push({});
        this.setState({"FormData": FormData});

    }

    removeOption(seq){

        let FormData = this.state.FormData;

        if (!('Options' in FormData)){
            FormData['Options'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Options.length;pos++){
            let item = FormData.Options[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Options = arrNew;
        this.setState({"FormData": FormData});

    }


    updateOption(seq, Option){

        let FormData = this.state.FormData;

        if (!('Options' in FormData)){
            FormData['Options'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Options.length;pos++){
            let item = FormData.Options[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Option;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Option);
        }

        FormData.Options = arrNew;
        this.setState({"FormData": FormData});

    }



    FormStepFlows(){

        let seqNext = 1;
        if (this.state.FormData.Flows){
            seqNext = this.state.FormData.Flows.length + 1;
        }
        return (
            <fieldset>
                <legend>Flows - how to get to this step</legend>
                <PD.Table type="list">
                    {this.state.FormData.Flows &&
                        this.state.FormData.Flows.map(
                            (Flow, posFlow) => 
                            {
                                let seq = posFlow+1;
                                return(
                                    this.FormFlow('edit', seq, Flow)
                                )
                            }
                        )
                    }
                    {this.FormFlow('new', seqNext)}
                </PD.Table>
            </fieldset>
        );

    }

    FormFlow(mode, seq, Flow = {}){
        return(
            <StepFlowRow key={seq.toString()} mode={mode} parent={this} Flow={Flow} seq={seq} selectSteps={this.state.ProcessSteps} idStep={this.state.idStep}/>
        )
    }


    FormStepArtefacts(){

        if (!this.state.FormData.type){
            return null
        }

        let modelStep = null;
        if (this.state.FormData.type in this.dsd.model.steps){
            modelStep = this.dsd.model.steps[this.state.FormData.type];
        }
        if (!modelStep){
            return null
        }

        let seqNext = 1;

        switch (modelStep.type){
            case 'bpmn:activity':
            case 'bpmn:exclusiveor':
            case 'bpmn:inclusiveor':
                if (this.state.FormData.Artefacts){
                    seqNext = this.state.FormData.Artefacts.length + 1;
                }
                return (
                    <fieldset>
                        <legend>Artefacts - inputs and outputs for this step</legend>
                        <PD.Table type="list">
                            {this.state.FormData.Artefacts &&
                                this.state.FormData.Artefacts.map(
                                    (stepArtefact, posStepArtefact) => 
                                    {
                                        let seq = posStepArtefact+1;
                                        return(
                                            this.FormStepArtefact('edit', seq, stepArtefact)
                                        )
                                    }
                                )
                            }
                            {this.FormStepArtefact('new', seqNext)}
                        </PD.Table>
                    </fieldset>
                );
                break;
        }

        return null;

    }

    FormStepArtefact(mode, seq, Artefact = {}){
        return(
            <StepArtefactRow key={seq.toString()} mode={mode} parent={this} Artefact={Artefact} seq={seq} Process={this.state.Process} seqStep={this.state.seqStep}/>
        )
    }

    addArtefact(){

        let FormData = this.state.FormData;

        if (!('Artefacts' in FormData)){
            FormData['Artefacts'] = [];
        }
        if (!FormData.Artefacts){
            FormData['Artefacts'] = [];
        }

        FormData.Artefacts.push({});
        this.setState({"FormData": FormData});

    }

    removeArtefact(seq){

        let FormData = this.state.FormData;

        if (!('Artefacts' in FormData)){
            FormData['Artefacts'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Artefacts.length;pos++){
            let item = FormData.Artefacts[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Artefacts = arrNew;
        this.setState({"FormData": FormData});

    }


    updateArtefact(seq, Artefact){

        let FormData = this.state.FormData;

        if (!('Artefacts' in FormData)){
            FormData['Artefacts'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Artefacts.length;pos++){
            let item = FormData.Artefacts[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Artefact;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Artefact);
        }

        FormData.Artefacts = arrNew;
        this.setState({"FormData": FormData});

    }

    FormStepResources(){

        if (!this.state.FormData.type){
            return null
        }

        let modelStep = null;
        if (this.state.FormData.type in this.dsd.model.steps){
            modelStep = this.dsd.model.steps[this.state.FormData.type];
        }
        if (!modelStep){
            return null
        }

        let seqNext = 1;

        switch (modelStep.type){
            case 'bpmn:activity':
            case 'bpmn:exclusiveor':
            case 'bpmn:inclusiveor':
                if (this.state.FormData.Resources){
                    seqNext = this.state.FormData.Resources.length + 1;
                }
                return (
                    <fieldset>
                        <legend>Resources - more information about this step</legend>
                        <PD.Table type="list">
                            {this.state.FormData.Resources &&
                                this.state.FormData.Resources.map(
                                    (stepResource, posStepResource) => 
                                    {
                                        let seq = posStepResource+1;
                                        return(
                                            this.FormStepResource('edit', seq, stepResource)
                                        )
                                    }
                                )
                            }
                            {this.FormStepResource('new', seqNext)}
                        </PD.Table>
                    </fieldset>
                );
                break;
        }

        return null;

    }

    FormStepResource(mode, seq, Resource = {}){
        return(
            <StepResourceRow key={seq.toString()} mode={mode} parent={this} Resource={Resource} seq={seq} Process={this.state.Process} seqStep={this.state.seqStep}/>
        )
    }

    addResource(){

        let FormData = this.state.FormData;

        if (!('Resources' in FormData)){
            FormData['Resources'] = [];
        }
        if (!FormData.Resources){
            FormData['Resources'] = [];
        }

        FormData.Resources.push({});
        this.setState({"FormData": FormData});

    }

    removeResource(seq){

        let FormData = this.state.FormData;

        if (!('Resources' in FormData)){
            FormData['Resources'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Resources.length;pos++){
            let item = FormData.Resources[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Resources = arrNew;
        this.setState({"FormData": FormData});

    }


    updateResource(seq, Resource){

        let FormData = this.state.FormData;

        if (!('Resources' in FormData)){
            FormData['Resources'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Resources.length;pos++){
            let item = FormData.Resources[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Resource;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Resource);
        }

        FormData.Resources = arrNew;
        this.setState({"FormData": FormData});

    }


};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Step {...props} navigation={navigation} location={location} params={params} />;
  }
